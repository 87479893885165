<template>
  <Transition name="bounce">
    <p v-if="visibleSuccess" class="successAlert">
      {{ updateStatusSuccessCaption }}
    </p>
  </Transition>

  <Transition name="bounce">
    <p v-if="visibleError" class="errorAlert">
      {{ updateStatusErrorCaption }}
    </p>
  </Transition>
</template>

<script>
export default {
  name: "ProjectAlert",
  props: {
    updateStatusSuccessCaption: { type: String, required: true },
    updateStatusErrorCaption: { type: String, required: true },
    visibleSuccess: { type: Boolean, required: true },
    visibleError: { type: Boolean, required: true }
  }
}
</script>

<style scoped>
.successAlert {
  text-align: center;
  color: forestgreen;
  font-family: Windsor, serif;
  font-weight: bold;
  font-size: 4.5vh;
}

.errorAlert {
  text-align: center;
  color: maroon;
  font-family: Windsor, serif;
  font-weight: bold;
  font-size: 4.5vh;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>