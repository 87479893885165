<template>
  <div class='mainColumn'>

    <div class='titleContainer' v-if="successesData?.showSuccessesFirstTimeTitle">
      <span v-html = "successesData?.praiseTitle" :class="titleClass"></span>
    </div>

<!--
    <div class="periodBackNextSelector">
      <img src="../../../assets/images/left-arrow.png" class="leftArrow" @click="leftClick" />

      <div :class="periodViewClass">
        {{ statusResultsData?.periodView }}
      </div>

      <img src="../../../assets/images/right-arrow.png" class="rightArrow" @click="rightClick" />
    </div>

    <div class='titleContainer' v-if="!successesData?.showSuccessesFirstTimeTitle">
      <span :class="titleClass">{{ successesData?.title }}</span>
    </div>
-->
    
  </div>
</template>

<script>
import router from '@/main';
import axios from 'axios';

export default {
  name: 'SuccessesPeriodSelector',
  props: ['successesData'],

  data() {
    return {
      titleClass: null,
    };
  },

  mounted() {
    if (window.Telegram.WebApp.colorScheme === 'dark') {
      this.titleClass = 'titleDarkVisible';
    } else {
      this.titleClass = 'titleLightVisible';
    }
  },

  methods: {
    showTitle() {
      this.titleClass = 'titleLightVisible';
    },

    hideTitle() {
      this.titleClass = 'titleLightHidden';
    },
  },
}
</script>

<style scoped>
.mainColumn {
  display: flex;
  flex-direction: column;
}

.titleContainer {
  align-self: center;
  text-align: center;
  align-items: center;
}

.titleDarkVisible {
  color: var(--text-foreground-dark);
  margin: auto;
  font-family: Garamond, serif;
  font-size: 3.5vh;
  text-align: center;
}

.titleDarkHidden {
  color: #BABABA;
  margin: auto;
  font-family: Garamond, serif;
  font-size: 3.5vh;
  text-align: center;
}

.titleLightVisible {
  color: var(--text-foreground-light);
  filter: brightness(0.75);
  margin: auto;
  font-family: Garamond, serif;
  font-size: 3.5vh;
  text-align: center;
}

.titleLightHidden {
  color: white;
  margin: auto;
  font-family: Garamond, serif;
  font-size: 3.5vh;
  text-align: center;
}

.periodBackNextSelector {
  width: 96vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 1.0vh;
}
</style>